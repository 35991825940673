import React from "react";
import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

const NavbarS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;

  @media (max-width: 680px) {
    display: none;
  }
`;
const Element = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #222222;
  margin: 0px 50px;
  cursor: pointer;
  padding: 20px 0px;

  text-transform: capitalize;
  a {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: normal;
    color: #222222;
    padding: 20px 0px;
  }
  border-bottom: ${(props) =>
    props.type === "picked" ? `4px solid #e44331` : `0px solid #e44331`};
`;
const NavbarSM = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: ${(props) =>
    props.open ? `1px solid #e1e1e1` : `0px solid #e1e1e1`};
  padding-bottom: 16px;

  @media (min-width: 681px) {
    display: none;
  }
`;
const ElementM = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #222222;
  margin: 0px 24px;
  cursor: pointer;
  padding: 10px 0px;
  padding-bottom: ${(props) => (props.type === "picked" ? `6px` : `10px`)};
  text-transform: capitalize;
  font-weight: 500;

  a {
    color: #222222;
    padding: 10px 0px;
    font-family: Work Sans;
  }

  border-bottom: ${(props) =>
    props.type === "picked" ? `4px solid #e44331` : `0px solid #e44331`};
`;

const HamburgerOpen = styled.div`
  width: 100%;
  height: 64px;
  border-top: 1px solid #e1e1e1;
  border-bottom: ${(props) =>
    props.open ? `0px solid #e1e1e1` : `1px solid #e1e1e1`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowDown = styled.div`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

function Navbar({ categories, location }) {
  const [pick, setPicked] = useState(-1);
  const [open, setOpen] = useState(false);
  const ref = useRef([]);
  const intl = useIntl();

  function filterCategoryName(name) {
    name = name.replaceAll("-", " ");
    name = name.replaceAll("/", "");
    return name;
  }

  useEffect(() => {
    let url = window.location.pathname;
    let blog = encodeURI(
      intl.formatMessage({
        id: "UrlBlog",
      })
    );

    let target = url.slice(url.search(blog) + blog.length, url.length);
    target = filterCategoryName(target.toLowerCase());
    console.log(target);

    if (target == "")
      target = intl.formatMessage({
        id: "BlogNavAllPosts",
      });

    if (
      url.includes(
        encodeURI(
          intl.formatMessage({
            id: "BlogNavFeatured",
          })
        )
      )
    ) {
      target = intl.formatMessage({
        id: "BlogNavFeatured",
      });
    }

    target = target.toLocaleLowerCase();

    ref.current.forEach((el, i) => {
      if (filterCategoryName(el.textContent.toLowerCase()).includes(target)) {
        setPicked(i);
      }
    });
  }, []);

  return (
    <>
      <NavbarS>
        {pick === 0 ? (
          <Element type="picked">
            {intl.formatMessage({
              id: "BlogNavAllPosts",
            })}
          </Element>
        ) : (
          <Element
            ref={(el) => (ref.current[0] = el)}
            onClick={() => setPicked(0)}
          >
            <Link
              to={`/${intl.formatMessage({
                id: "UrlBlog",
              })}/`}
            >
              {intl.formatMessage({
                id: "BlogNavAllPosts",
              })}
            </Link>
          </Element>
        )}
        {pick === 1 ? (
          <Element type="picked">
            {intl.formatMessage({
              id: "BlogNavFeatured",
            })}
          </Element>
        ) : (
          <Element
            ref={(el) => (ref.current[1] = el)}
            onClick={() => setPicked(1)}
          >
            <Link
              to={`/${intl.formatMessage({
                id: "UrlBlog",
              })}/${intl.formatMessage({
                id: "UrlFeatured",
              })}/`}
            >
              {intl.formatMessage({
                id: "BlogNavFeatured",
              })}
            </Link>
          </Element>
        )}
        {categories.map((category, i) => {
          return pick === i + 2 ? (
            <Element type="picked">{filterCategoryName(category)}</Element>
          ) : (
            <Element
              ref={(el) => (ref.current[i + 2] = el)}
              onClick={() => setPicked(i + 2)}
            >
              <Link
                to={`/${intl.formatMessage({
                  id: "UrlBlog",
                })}/${category}/`}
              >
                {filterCategoryName(category)}
              </Link>
            </Element>
          );
        })}
      </NavbarS>
      <NavbarSM open={open}>
        <HamburgerOpen
          open={open}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <ArrowDown />
        </HamburgerOpen>
        {open && (
          <>
            {pick === 0 ? (
              <ElementM type="picked">
                {intl.formatMessage({
                  id: "BlogNavAllPosts",
                })}
              </ElementM>
            ) : (
              <ElementM onClick={() => setPicked(0)}>
                <Link
                  to={`/${intl.formatMessage({
                    id: "UrlBlog",
                  })}/`}
                >
                  {intl.formatMessage({
                    id: "BlogNavAllPosts",
                  })}
                </Link>
              </ElementM>
            )}
            {pick === 1 ? (
              <ElementM type="picked">
                {intl.formatMessage({
                  id: "BlogNavFeatured",
                })}
              </ElementM>
            ) : (
              <ElementM onClick={() => setPicked(1)}>
                <Link
                  to={`/${intl.formatMessage({
                    id: "UrlBlog",
                  })}/${intl.formatMessage({
                    id: "UrlFeatured",
                  })}/`}
                >
                  {intl.formatMessage({
                    id: "BlogNavFeatured",
                  })}
                </Link>
              </ElementM>
            )}
            {categories.map((category, i) => {
              return pick === i + 2 ? (
                <ElementM type="picked">
                  {filterCategoryName(category)}
                </ElementM>
              ) : (
                <ElementM onClick={() => setPicked(i + 2)}>
                  <Link
                    to={`/${intl.formatMessage({
                      id: "UrlBlog",
                    })}/${category}/`}
                  >
                    {filterCategoryName(category)}
                  </Link>
                </ElementM>
              );
            })}
          </>
        )}
      </NavbarSM>
    </>
  );
}

export default Navbar;
